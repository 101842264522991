import * as React from "react"
import { Link, type HeadFC } from "gatsby"
import styled from "@emotion/styled"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { SectionWrapper, Node } from "../components/Sections"
import { HeadingTitle } from "../components/styling/Heading"
import BorderWrapper from "../components/styling/BorderWrapper"
import { StaticImage } from "gatsby-plugin-image"

const title = "About"

const AboutBody = styled(Node.default)`
  p {
    margin-bottom: 10px;
  }
`

const AboutPage = () => {
  return (
    <Layout footerOpacity={1}>
      <SectionWrapper id="Bio" heading={<HeadingTitle name={title} />}>
        <AboutBody
          aside={
            <BorderWrapper>
              <StaticImage
                src="../images/about/IMG_2405.jpg"
                alt="A selfie of me with pink and purple curly hair, glasses, lite makeup, and smiling in a red striped dress and a red choker."
              />
            </BorderWrapper>
          }
        >
          <Node.p style={{ marginBottom: "10px" }}>Hiya!</Node.p>
          <Node.p>My name is Violet (She/They).</Node.p>
          <Node.p>
            Some of my earliest memories are my Uncle introducing me to video
            games, and I've loved them since. Throughout my life, games have
            given me spaces to feel safe when I didn't feel safe elsewhere, to
            better understand myself, to better understand others, and to simply
            enjoy my life more. I want to create those same experiences in games
            for others through accessibility, inclusivity, and creativity.
          </Node.p>
          {/* <Node.p>
            My work experience has been quite a rollercoaster of a journey. From
            a music composition degree, over 8 years of web development, and
            over 5 years of working on games, I love applying all the various of
            skills I've learned to solve problems in unique ways.
          </Node.p> */}
          <Node.p>
            I am currently based in Los Angeles where I spend the time I'm not
            making games playing them. Lately, I've been playing Final Fantasy
            XIV and Baulder's Gate 3, working on my next game (
            <Link to="/case-studies/loara">Labours of a Rising Adventurer</Link>
            ), and doing UI/UX design work for a few in-development indie games.
          </Node.p>
        </AboutBody>
        {/* <Node.multiCol
          col1={
            <BorderWrapper
              caption={
                "Finding Savi and Ezran cuddled up like this is a regular occurance in our appartment."
              }
            >
              <StaticImage
                src="../images/about/TheBoys.jpg"
                alt="A chocolate lab, Ezran, laying on a bed with a blue comforter with a black cat, Savi, laying on top of Ezran. Both are looking calmly at the camera."
              />
            </BorderWrapper>
          }
          col2={
            <BorderWrapper
              caption={
                "Me and my wife, Dorothy, in our 2023 Halloween costumes!"
              }
            >
              <StaticImage
                src="../images/about/MeAndDorothyHalloween.jpg"
                alt="Me and Dorothy posing together in our 2023 halloween costumes making silly faces. I'm in a witch costume in dark makeup, a black dress with a spider web pattern, a black witch hat, and  spider web earrings. Dorothy's in the colorful 13 going on 30 thriller dress with an added purple blazer for warmth."
              />
            </BorderWrapper>
          }
          col3={
            <BorderWrapper
              caption={
                <>
                  My corner I'm most often found in.* <br /> *All models
                  pictured have been compensated fairly.
                </>
              }
            >
              <StaticImage
                src="../images/about/MyCorner.jpg"
                alt="A picture of an office corner with a variety of decorations. On one wall is a series of Posters from Super metroid, Final Fantasy XIV, Kingdom Hearts 3, Maple Story, and Nier Automata, and on the other wall is two posters of a creative interpretation of characters from Final Fantasy 6. Against that same wall is a small cabinet showcasing a collection of old consoles including the orginal NES, a SNES, a Sega Genesis, a Playstation 1 and 2, and a Nintendo 64. Next to this cabinet is a chocolate lab, Ezran, sitting and posing for the camera, to the left of Ezran is a dark PC case with violet glowing lights."
              />
            </BorderWrapper>
          }
        /> */}
      </SectionWrapper>
    </Layout>
  )
}

export default AboutPage

export const Head: HeadFC = () => <Seo title={title} />
