import * as React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Main } from "./styling/Wrapper"
import { PageProps } from "gatsby"

interface Props {
  withWrapper?: boolean
  footerOpacity?: number
  children?: React.ReactNode
  pageprops?: PageProps
}

function Layout({
  withWrapper = true,
  footerOpacity = 1,
  children,
  pageprops,
}: Props) {
  return (
    <>
      <Header pageprops={pageprops} />
      {withWrapper ? (
        <Main as="main">{children}</Main>
      ) : (
        <main>{children}</main>
      )}
      <Footer opacity={footerOpacity} />
    </>
  )
}

export default Layout
